function App() {
  const backgroundImageUrl = process.env.PUBLIC_URL + '/bg.png';

  return (
    <div className="w-screen main-content  overflow-hidden flex flex-col px-5 bg-black" style={{ backgroundImage: `url(${backgroundImageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className='pt-5'>
        <img className="h-6 md:h-8 object-contain" src="/logo-light.png" alt="logo" />
      </div>
      <div className="flex-1 flex items-center justify-center">
        <img className="xl:w-1/3 md:w-1/2 w-full object-contain" src="/home-phone.png" alt="" />
      </div>
      <div className="text-white font-semibold w-80 mx-auto text-center">
        Та доорх холбоосоор iDAX гар утасны апп татан авч ашиглана уу.
      </div>
      <div className="py-5 flex justify-center space-x-5">

        <a
          href="https://apps.apple.com/mn/app/idax-crypto-exchange/id1619039755"
          className="bg-white py-2 w-36 flex justify-center space-x-2 rounded font-medium"
        >
          <img src="/appstore.png" className="h-6 object-contain" alt="" />
          <span>IOS</span>
        </a>
        <a
          href="https://onelink.to/qtmtn5"
          className="bg-white py-2 w-36 flex justify-center space-x-2 rounded font-medium"
        >
          <img src="/playstore.png" className="h-6 object-contain" alt="" />
          <span>Android</span>
        </a>
      </div>
    </div>
  );
}

export default App;
